* {
  outline: none;
}

.blue-link {
  font-weight: normal;
  text-decoration: underline;
  font-size: 14px;
  text-align: left;
  color: #11a0ff;
}

.invalid-feedback {
  color: red;
  font-size: 14px;
  margin: 0 8px;
  display: block;
}

.rm-fpassword {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 8px;
}

.main-container {
  min-height: calc(100vh - 280px);
}

.MuiInputBase-input.Mui-disabled {
  background: #f8f8f8f8;
  cursor: not-allowed;
}

.custom-modal .modal-title {
  text-align: center;
  width: calc(100% - 55px);
  color: var(--secColor);
  text-transform: uppercase;
  font-size: 20px;
}

.show.custom-modal {
  display: block;
}

.static-container {
  width: 100%;
  padding: 40px 15px;
  max-width: 1240px;
  margin: 0 auto;
}

.static-wrapper .pp-banner img {
  max-width: 100%;
}

.static-wrapper .pp-label {
  width: 320px;
}

.blue-link.rgLinkBtn {
  white-space: pre;
  margin-bottom: 0;
  min-width: 243px;
}

.blue-link.rgLinkBtn:focus {
  outline: none;
}

@media (max-width: 700px) {
  .row.cstmWrapperfrCrd {
    min-width: 95vw;
  }

  .custom-control.custom-switch .form-ghead {
    width: 210px;
  }

  .cc-wrapper {
    padding-right: 0 !important;
  }

  .crdImgWrper {
    width: 100vw;
    padding-bottom: 25px;
    text-align: center;
  }

  .mb-xs-4 {
    line-height: 34px;
  }

  .search-results-label {
    margin-bottom: 20px;
  }

  .tmrTxt {
    margin: 10px 0;
    /* text-align: center; */
  }
}

.MuiAutocomplete-root.adtnlClsSrch .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0 !important;
  padding-left: 12px !important;
}

.MuiAutocomplete-root.adtnlClsSrch .MuiAutocomplete-popupIndicator {
  display: none !important;
}

/* 
.backBlurImg{
	position: absolute;
    top: -35px;
    left: 0;
    object-fit: cover;
    filter: blur(16px);
    height: 329px;
    width: 100%;
    z-index: -1;
} */

/* .auctionCard .acImg{
	z-index: 100;
} */

.list-inline-item.cstmWdth {
  max-width: 120px;
}

.list-inline-item.cstFxWdth {
  width: max-content;
  min-width: 180px;
}

.auctionCard .acImg img.pro-img {
  object-fit: contain !important;
  height: 100%;
  background: #dddddd;
}

.bdBtnStk > div {
  margin-right: 10px;
}

.bdBtnStk .fq-mark.fa-question-circle {
  margin-left: 0;
}

@media (max-width: 600px) {
  .searchFilters .searchTitle {
    font-size: 20px !important;
  }

  .searchFilters .sfSlogan {
    position: relative !important;
    top: unset !important;
    right: unset !important;
  }

  .searchFilters .sfSlogan h3 {
    font-size: 13px !important;
    color: #044a6f !important;
  }
}
