body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  /* --fontFamily: 'Open Sans'; */
  --primColor: #008f5c;
  --secColor: #7f431d;
}

@font-face {
  font-family: 'Kristy-Regular';
  src: url('./fonts//kirsty_rg.otf');
  font-weight: normal;
  font-display: swap;
}

.kristy {
  font-family: 'Kristy-Regular' !important;
  font-weight: 400 !important;
}

.tmrWrapper .timer-box {
  width: 250px;
  height: 60px;
}

.tmrWrapper .timer-box .tmCont {
  font-size: 25px;
}

.product-wrapper .pb-custom-input input {
  padding-left: 35px;
}

/* .auctionCard.listView .acImg .pro-img {
  min-height: 210px !important;
} */

.auctionCard.listView .acImg {
  height: 210px;
  border-radius: 0px !important;
  overflow: hidden;
}

@media (max-width: 600px) {
  .logoContainer {
    flex-wrap: wrap-reverse;
    width: 235px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .header-logo {
    max-height: 116px !important;
    margin-top: 7px !important;
  }

  .hedrTxt {
    width: 229px !important;
    margin-top: 35px !important;
  }
}
